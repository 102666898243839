export enum QueueStatus {
  PENDING = 0,
  PROCESSING = 1,
  COMPLETED = 2,
  REJECTED = 3,
}
export enum EventStatus {
  PENDING = 0,
  COMPLETED = 1,
  CANCELLED = 2,
}
import lodash from "lodash";

export enum Role {
  SUPERADMIN = "superadmin",
  STUDIO = "studioadmin",
  DISTRICT = "districtadmin",
  ORG = "admin",
}
export enum CommanName {
  organizations = "organizations",
  studios = "studios",
  schoolStudent = "school_Student",
  schoolVisitor = "school_Visitor",
  superAdmin = "superadmin",
}
export enum PreferenceIds {
  quickStartPref = "quickStartPref",
  formFactorPrint = "formFactorPrintingOutputTab",
  formFactorExport = "formFactorExportingOutputTab",
  designTypePrint = "designTypePrintingOutputTab",
  designTypeExport = "designTypeExportingOutputTab",
  designOrVariationPrint = "designPrintingOutputTab",
  designOrVariationExport = "designExportingOutputTab",
}

export enum FormFactorDesignTypePreference {
  formFactorPrintOutput = "formFactorPrintingOutputTab",
  designTypePrintOutput = "designTypePrintingOutputTab",
  formFactorExportOutput = "formFactorExportingOutputTab",
  designTypeExportOutPut = "designTypeExportingOutputTab",
}
export enum ExpandSupportingChar {
  TimeFormat = "@@",
  StringMethod = "%%",
}

export enum OrgManagerType {
  region = "-MWDBNtwEvc6cnqpLnmq",
  conference = "-MWDBQ84TpYFZRZAH_5Q",
  league = "-MWDBLvKRZNXySrQOdy9",
}

export enum OrgType {
  SCHOOL = "school",
  CONFERENCE = "conference",
  SPORTSTEAM = "sportsteam",
  COMPANY = "Company",
  PROPERTY = "property",
  PROGRAM = "program",
}

export enum Browsers {
  CHROME = "chrome",
  FIREFOX = "firefox",
  EDGE = "edge",
  SAFARI = "safari",
  IE = "IE",
  OPERA = "opera",
  UNKNOWN = "unknown",
}

export enum OS {
  MAC_OS = "Mac OS",
  IOS = "iOS",
  WINDOWS = "Windows",
  ANDROID = "Android",
  LINUX = "Linux",
  UNKNOWN = "unknown",
}

export enum FlagBehaviorStatus {
  NONE = 0,
  ALWAYS_REQUIRE_APPROVAL = 1,
  AUTOMATIC_APPROVAL = 2,
  AUTOMATIC_REFUSAL = 3,
}
export enum LicenseType {
  Expiring = "expiring",
  Count_Prepaid = "count_prepaid",
}
export enum LicenseStatus {
  Expire = "expired",
  Revoked = "revoked",
  Ok = "ok",
}

export enum Attributes {
  VIEW_ONLY = 1,
  VIEW_ANON = 2,
  FULL_EDIT = 3,
}

export const RandamNames = {
  fName: [
    "Casey",
    "River",
    "Sheridan",
    "Sky",
    "Harper",
    "Robin",
    "Terry",
    "Logan",
    "Sidney",
    "Drew",
    "Quinn",
    "Sage",
    "Madison",
    "Dominique",
    "Kennedy",
    "Ainsley",
    "Addison",
    "Reese",
    "Sawyer",
    "London",
    "Rory",
    "Remy",
    "Arden",
    "Shiloh",
    "Blair",
    "Kendall",
    "Morgan",
    "Micah",
    "Emery",
    "Aubrey",
    "Dakota",
    "Devon",
    "Dylan",
    "Kelsey",
    "Shannon",
    "Vivian",
    "Ellis",
    "Randy",
    "Kim",
    "Shirley",
    "Whitney",
    "Harlow",
    "Luca",
    "Chase",
    "Gale",
    "Reilly",
    "Les",
    "Murphy",
    "Mackenzie",
    "Rowan",
    "Phoenix",
    "Shelby",
    "Hayden",
    "Kelly",
    "Cameron",
    "Hayley",
    "Lee",
    "Darby",
    "Paris",
    "Ryan",
    "Elliott",
    "Lindsay",
    "Meredith",
    "Hunter",
    "Darcy",
    "Dale",
    "Finley",
    "Kai",
    "Hollis",
    "Keegan",
    "Marlowe",
    "Taylor",
    "Jordan",
    "Avery",
    "Dana",
    "Parker",
    "Joyce",
    "Bailey",
    "Jamie",
    "Skyler",
    "Emerson",
    "Jocelyn",
    "Alex",
    "Alexis",
    "Adrian",
  ],

  lName: [
    "Thomas",
    "Torres",
    "Bennett",
    "Ross",
    "King",
    "Peterson",
    "Coleman",
    "Edwards",
    "Barnes",
    "Clark",
    "Cooper",
    "Watson",
    "Robinson",
    "Turner",
    "Cox",
    "Williams",
    "Smith",
    "Morris",
    "Brooks",
    "Collins",
    "Gray",
    "Ward",
    "Davis",
    "Wood",
    "Taylor",
    "Roberts",
    "Morgan",
    "Cook",
    "Hall",
    "Miller",
    "White",
    "Martinez",
    "Rodriguez",
    "Perez",
    "Kelly",
    "Perry",
    "Lee",
    "Richardson",
    "Phillips",
    "Price",
    "Powell",
    "Hernandez",
    "Stewart",
    "Jackson",
    "Young",
    "Howard",
    "Parker",
    "Bell",
    "Sanchez",
    "Ramirez",
    "Rivera",
    "Sanders",
    "Wright",
    "Mitchell",
    "Anderson",
    "Lopez",
    "Campbell",
    "Thompson",
    "Bailey",
    "James",
    "Lewis",
    "Evans",
    "Reed",
    "Scott",
    "Garcia",
    "Harris",
    "Allen",
    "Nelson",
    "Hill",
    "Carter",
    "Rogers",
    "Jenkins",
    "Baker",
    "Wilson",
    "Martin",
    "Murphy",
    "Gonzalez",
    "Moore",
    "Green",
    "Henderson",
    "Walker",
    "Adams",
    "Jones",
    "Brown",
    "Johnson",
  ],
};
export enum PhotoStatus {
  Not_Yet_Reviewed = "",
  Approved = 1,
  Rejected = 0,
}

export enum PhotoStatusKey {
  Not_Yet_Reviewed = "Not yet reviewed",
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum IndividualWithoutOrgID {
  Included = 1,
  Skipped = 2,
}
export enum IncludeIDStatus {
  Any = "any",
  Active_Only = "1",
}
export enum VisitorStatus {
  Completed = "completed",
  Pending = "awaiting approval",
  OnSite = "on campus",
  NotYetOnCampus = "not yet on campus",
}

export enum OrgMgrStatus {
  APPLIED = "applied",
  APPROVED_AWAITING_CONFIGURATION = "approved_awaiting_configuration",
  ACTIVE = "active",
}

export enum BundleType {
  SMALL = 1,
  MEDIUM = 2,
  LARGE = 3,
  BOUTIQUE = 4,
}

export enum ConsumablePaidBy {
  ORG = "org",
  TEAMMATE = "teammate",
}

export enum SourceValue {
  OneRoster = 'oneRoster',
  CSV = 'csv',
  Aeries = 'aeries',
}

export enum BundleState {
  ACTIVE = "Active",
  EXPIRED = "Expired",
}

export enum ZohoApiType {
  READ_CUSTOMER = "readCustomer",
  GET_CUSTOMER_CARDS = "getCustomerCards",
  CREATE_CUSTOMER = "createCustomer",
  UPDATE_CUSTOMER = "updateCustomer",
  REQUEST_PAYMENT_METHOD_LINK = "requestPaymentMethodLink",
  DELETE_CUSTOMER_CARD = "deleteCustomerCard",
}

export namespace OrgMgrStatus {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredStatuses = lodash.filter(OrgMgrStatus, (value, key) =>
      lodash.isString(value)
    );

    let displayNames = {
      [OrgMgrStatus.APPLIED]: "Applied",
      [OrgMgrStatus.APPROVED_AWAITING_CONFIGURATION]:
        "Approved awaiting configuration",
      [OrgMgrStatus.ACTIVE]: "Active",
    };

    return lodash.map(filteredStatuses, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum IndCertStatus {
  FINAL_APPROVAL_DONE = 100,
  ADDED_TO_PRINT_QUEUE = 101,
  COLLECTED_TO_PRINT = 106,
  IN_PRINTING = 111,
  AWAITING_SHIPPING = 116,
  COMPLETED = 121,
}

export enum PrintOrderStatus {
  QUEUE = "queue",
  PRINTING = "printing",
  AWAITING_SHIPPING = "awaiting_shipping",
  SHIPPED = "shipped",
}

export namespace PrintOrderStatus {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredStatuses = lodash.filter(PrintOrderStatus, (value, key) =>
      lodash.isString(value)
    );

    let displayNames = {
      [PrintOrderStatus.QUEUE]: "Queued, awaiting Output",
      [PrintOrderStatus.PRINTING]: "Being Output, awaiting completion",
      [PrintOrderStatus.AWAITING_SHIPPING]:
        "Output complete, awaiting shipping",
      [PrintOrderStatus.SHIPPED]: "Shipped, done",
    };

    return lodash.map(filteredStatuses, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum StorageHost {
  S3 = "s3",
  FIREBASE = "firebase",
  UNKNOWN = "unknown",
}

export namespace IndCertStatus {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredStatuses = lodash.filter(IndCertStatus, (value, key) =>
      lodash.isNumber(value)
    );

    let displayNames = {
      [IndCertStatus.FINAL_APPROVAL_DONE]: "Accepted",
      [IndCertStatus.ADDED_TO_PRINT_QUEUE]: "Accepted, Print ordered",
      [IndCertStatus.COLLECTED_TO_PRINT]: "Accepted, Print in production",
      [IndCertStatus.IN_PRINTING]: "Accepted, Print in production",
      [IndCertStatus.AWAITING_SHIPPING]: "Accepted, Print in production",
      [IndCertStatus.COMPLETED]: "Accepted, Printed",
    };

    return lodash.map(filteredStatuses, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum CardStockType {
  TESLIN = "tesLin",
  PVC = "pvc",
}

export namespace CardStockType {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredTypes = lodash.filter(CardStockType, (value, key) =>
      lodash.isString(value)
    );

    let displayNames = {
      [CardStockType.TESLIN]: "Teslin",
      [CardStockType.PVC]: "PVC",
    };

    return lodash.map(filteredTypes, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum OrderType {
  BULK_ORDER = "bulkOrder",
  RP_ORDER = "rpOrder",
}

export enum ShippingPrefs {
  PRIORITY_MAIL = 1,
  PRIORITY_EXPRESS = 2,
  PICKUP_OFFICE = 3,
}

export namespace ShippingPrefs {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredPrefs = lodash.filter(ShippingPrefs, (value, key) =>
      lodash.isNumber(value)
    );

    let displayNames = {
      [ShippingPrefs.PRIORITY_MAIL]: "Priority Mail (2-3 days)",
      [ShippingPrefs.PRIORITY_EXPRESS]: "Priority Express (1-2 days)",
      [ShippingPrefs.PICKUP_OFFICE]: "Pickup",
    };

    return lodash.map(filteredPrefs, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum LicensePricePref {
  RETAIL = 1,
  WHOLESALE = 2,
}

export enum SendMsgOnPassSetOpts {
  NO = "no",
  EMAIL_OR_SMS = "emailOrSMS",
  SMS_OR_EMAIL = "smsOrEmail",
}

export namespace SendMsgOnPassSetOpts {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredOpts = lodash.filter(SendMsgOnPassSetOpts, (value, key) =>
      lodash.isString(value)
    );

    let displayNames = {
      [SendMsgOnPassSetOpts.NO]: "No",
      [SendMsgOnPassSetOpts.EMAIL_OR_SMS]: "Email or SMS",
      [SendMsgOnPassSetOpts.SMS_OR_EMAIL]: "SMS or Email",
    };

    return lodash.map(filteredOpts, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export const renderJobStatusOrder = {
  queued: 1,
  "collecting content (step 1 of 3)": 2,
  "processing contents (step 2 of 3)": 3,
  "preparing download (step 3 of 3)": 4,
  completed: 10,
};

export enum ImportDisagreement {
  KEEP_EXISTING = 1,
  ADOPT_NEW = 2,
}
export namespace ImportDisagreement {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredOpts = lodash.filter(ImportDisagreement, (value, key) =>
      lodash.isNumber(value)
    );

    let displayNames = {
      [ImportDisagreement.KEEP_EXISTING]: "Keep existing value",
      [ImportDisagreement.ADOPT_NEW]: "Adopt new value",
    };

    return lodash.map(filteredOpts, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum ImportNewDataNull {
  KEEP_EXISTING = 1,
  CLEAR_EXISTING = 2,
}
export namespace ImportNewDataNull {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredOpts = lodash.filter(ImportNewDataNull, (value, key) =>
      lodash.isNumber(value)
    );

    let displayNames = {
      [ImportNewDataNull.KEEP_EXISTING]: "Keep existing value",
      [ImportNewDataNull.CLEAR_EXISTING]: "Clear existing value",
    };

    return lodash.map(filteredOpts, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum ImportActions {
  INSERT = "add",
  UPDATE = "update",
  IGNORE = "ignore",
  NO_CHANGES = "no_changes",
}

export enum ImportOptions {
  ALL = 1,
  UPDATES_ONLY = 2,
  ADDITIONS_ONLY = 3,
}
export namespace ImportOptions {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredOpts = lodash.filter(ImportOptions, (value, key) =>
      lodash.isNumber(value)
    );

    let displayNames = {
      [ImportOptions.ALL]: "Import All",
      [ImportOptions.UPDATES_ONLY]: "Import Updates Only",
      [ImportOptions.ADDITIONS_ONLY]: "Import Additions Only",
    };

    return lodash.map(filteredOpts, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum GameStatus {
  // should be in ascending order because Math min function is used to calculate overall status
  DISAGREEMENT = 101,
  PENDING = 102,
  AGREED = 103,
  CERTIFIED = 104,
}

export namespace GameStatus {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredOpts = lodash.filter(GameStatus, (value, key) =>
      lodash.isNumber(value)
    );

    let displayNames = {
      [GameStatus.PENDING]: "Pending",
      [GameStatus.AGREED]: "Agreed",
      [GameStatus.DISAGREEMENT]: "Disagreement",
      [GameStatus.CERTIFIED]: "Certified",
    };

    return lodash.map(filteredOpts, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum GameOutcome {
  COMPLETED = "completed",
  FORFEIT = "forfeit",
  BYE = "bye",
  TIE = "tie",
}

export namespace GameOutcome {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredOpts = lodash.filter(GameOutcome, (value, key) =>
      lodash.isString(value)
    );

    let displayNames = {
      [GameOutcome.COMPLETED]: "Completed",
      [GameOutcome.FORFEIT]: "Forfeit",
      [GameOutcome.BYE]: "BYE",
      [GameOutcome.TIE]: "Tie",
    };

    return lodash.map(filteredOpts, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum ElasticEndPoint {
  IND_LASTNAME = 63258,
  IND_F_L_EXACT_NAME = 54874,
  IND_F_L_NAME = 65874,
  IND_STU_ID = 98745,
  IND_STU_ID_BIRTH = 25487,
  IND_VISITOR_F_L_NAME = 36541,
  IND_VISITOR_MOBILE_NUM = 85477,
  IND_ROLE = 26479,
  IND_LIST = 59874,
  REMOVE_INDS = 26745,
  CCD_ORG_NAME = 16548,
  TOTAL_IND_COUNT = 95648,
  IND_F_L_NAME_WITH_1_MATCH = 86412,
  IND_MOBILE_IND_ROLE_WITH_ORG_ID = 36959,
  IND_MOBILE_2_IND_ROLE_WITH_ORG_ID = 29375,
  REMOVE_ORG = 99554,
  BULK_INSERT_INDS = 66254,
}

export enum FileMimeType {
  JSON = "application/json",
  SVG = "image/svg+xml",

  // --- images
  JPEG = "image/jpeg",
  PNG = "image/png",
  GIF = "image/gif",
  BMP = "image/bmp",
  TIFF = "image/tiff",
  WEBP = "image/webp",
}

export enum S3Bucket {
  IMAGES_POOL = "images-pool",
  TEST_HIGH5_IMAGE = "test-high5-image",
  HIGH5_PROD = "high5prod",
}

export enum StudioSearchType {
  NAME = "name",
}

export enum CustomTokenReqType {
  SELF = "self",
  IND = "individual",
  ORG = "organization",
  STUDIO = "studios",
  DISTRICT ="district",
}

export enum UseState {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum SportsFeesStatus {
  UNPAID = "Unpaid",
  PAID = "Paid",
  GIFTED = "Gifted",
  REFUNDED = "Refunded",
}

export namespace SportsFeesStatus {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredStatuses = lodash.filter(SportsFeesStatus, (value, key) =>
      lodash.isString(value)
    );

    let displayNames = lodash
      .chain(filteredStatuses)
      .mapValues()
      .keyBy((v) => v)
      .value();

    return lodash.map(filteredStatuses, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum PhotoUsedInID {
  CURRENT_YEAR_ONLY = 1,
  MOST_RECENT_AVAILABLE_YEAR = 2,
}

export enum MsgMedium {
  SMS = "sms",
  EMAIL = "email",
  EMAIL_HTML = "emailHtml",
}

export enum PaymentPurposeCode {
  STRIPE_REGISTRATION_FEES = 2001,
  STRIPE_SEASON_FEES = 2002,
}

export enum CertApprovalStatus {
  Approved = 1,
  Rejected = 2,
}

export enum OrgsListBy {
  LEAGUE_ID = "leagueID",
  PARTNER_ORG = "partnerOrg",
  PARENT_ID = "parentID",
  ALL = "all",
}

export enum OrgSearchType {
  NAME = "name"
}

export enum DesignProvideBy {
  ORG = "Organization",
  HIGH5 = "High5"
}

export enum TimeVaryingType {
  YESNO = 1,
  TIME = 2,
  DATE = 3
}

export enum TimeVaryingMethod {
  YES = 1,
  NO = 2,
  YESINTIME = 3,
  NOINTIME = 4,
  YESAFTERTIME = 5,
  NOAFTERTIME = 6,
  YESINDATETIME = 7,
  NOINDATETIME = 8,
  YESAFTERDATETIME = 9,
  NOAFTERDATETIME = 10,
}

export enum ScanStatus {
  ATTENDED = "Attended",
  PRESENT = "Currently Present",
  REFUSED= "Refused Access",
  NOTSCANNED = "Not Scanned"
}

export enum TicketStatus {
  CONFIRMED = "Confirmed",
  INVITED = "Invited",
  DECLINE = "Decline",
  COMPLEMENTARY = "Complementary",
  REFUNDED = "Refunded",
}

export enum RemoveMethod{
  MANUAL ="manual",
  BULKREMOVE = "bulkDelete",
  BLACKBAUD = "blackbaud",
  DUPCHECK = "dupCheck",
}

export enum ToDoStatus {
  ACTIVE = "active",
  COMPLETED = "completed",
  UNKNOWN = "unknown",
}

export const ToDoManagerClass = {
  PLIC: {
    REG_CONFLICT: 1001,
  },
  ORG_MANAGER: {
    SETUP: 2001,
    AGREEMENT: 2002,
  },
  ORG: {
    SETUP: 3001,
    AGREEMENT: 3002,
    CREDIT_CARD: 3003,
  },
  LICENSE: {
    LOW_CREDIT_BALANCE: 4001,
  },
  THIRD_PARTY_LICENSE: {
    LOW_CREDIT_BALANCE: 5001,
  },
  LEAGUE_RULES: {
    MAX_ACTIVE_ID_PER_TEAM_EXCEEDS: 6001,
  },
};

export enum ToDoManagerr {
  PLIC = "plic",
  ORG_MANAGER = "org_manager",
  ORG = "org",
  LICENSE = "LICENSE",
  THIRD_PARTY_LICENSE = "third_party_license",
  LEAGUE_RULES = "league_rules",
}

export enum IndRole {
  TEACHER = 'teacher',
  STAFF = 'staff',
  STUDENT = 'student',
  VISITOR = 'visitor',
  GUARDIAN = 'guardian'
}

export enum AlbumType {
  ID = 'id',
  YEARBOOK = 'yearbook',
  GENERAL = 'general'
}

export enum AlbumTypeOrgKey {
  ID = 'album_used_for_ID',
  YEARBOOK = 'album_used_for_yearbook',
  GENERAL = 'album_used_for_general'
}

export enum SlotStatus {
  CONFIRMED = 'confirmed',
  DECLINED = 'declined',
  CHANGED = 'changed',
  PROPOSED = 'proposed'
}

export const LocalStorageKeys = {
  lang: "lang",
  _2FAData: "_2FAData",
  H5Trace: "h5:tracePP",
  isPWAInstallationDetectedBefore: "installedPWA",
  chunkReloadAt: "chunkReloadAt",
  cacheCleanedAt: "cacheCleanedAt",
  claimsUpdatedAt: "claimsUpdatedAt",
};

export enum AccessRole {
  PERSON_AT_ORG = "PAO",
  TRUSTED_CHILD_ST = "ST",
  TRUSTED_ADULT_TE = "TE",
  TRUSTED_ADULT_GU = "GU",
  EVENT_TICKET_TRACKER = "ETT",
  ADMIN = "ADM",
  SBU = "SBU",
  TRUSTED_ADULT_VI = "VI"
}

export const IndexedDBKeys = {
  CachedID: "CachedID"
};

export const NotificationType = {
  GENERAL_DATA_DROPS: 'General Data Drops',
  SCHOOL_UPLOADS: 'School Uploads',
}