import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { AnimateModule } from 'primeng/animate';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { TabViewModule } from 'primeng/tabview';
import { GetIndCountPipe } from './pipes/getIndCount.pipe';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { GetOrgUpcommingEventPipe } from './pipes/get-org-upcomming-event.pipe';
import { TieredMenuModule } from 'primeng/tieredmenu';
@NgModule({
  declarations: [ NumbersOnlyDirective ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    MessagesModule,
    ToastModule,
    InputTextModule,
    AnimateModule,
    TableModule,
    CardModule, 
    DropdownModule,
    DialogModule,
    HttpClientModule,
    SkeletonModule,
    ConfirmDialogModule,
    TabViewModule,
    GetIndCountPipe,
    OverlayPanelModule,
    InfiniteScrollDirective,
    GetOrgUpcommingEventPipe,
    TieredMenuModule,
  ],
  exports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    MessagesModule,
    ToastModule,
    InputTextModule,
    AnimateModule,
    TableModule,
    CardModule,
    DropdownModule,
    DialogModule,
    HttpClientModule,
    SkeletonModule,
    ConfirmDialogModule,
    NumbersOnlyDirective,
    TabViewModule,
    GetIndCountPipe,
    OverlayPanelModule,
    InfiniteScrollDirective,
    GetOrgUpcommingEventPipe,
    TieredMenuModule
  ],
  providers: [MessageService, ConfirmationService, GetOrgUpcommingEventPipe],
})
export class SharedModule { }
