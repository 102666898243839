import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderElement: HTMLElement;

  constructor() {
    this.loaderElement = document.createElement('div');
    this.loaderElement.className = 'loader-overlay';
  }

  show(text: string = 'Loading...') {
    this.loaderElement.innerHTML = `
      <div class="loader-container">
        <div class="loader-spinner-box">
          <div class="loader"></div>
          <div class="loading-text">${text}</div>
        </div>
      </div>
    `;
    document.body.appendChild(this.loaderElement);
  }

  hide() {
    if (this.loaderElement.parentNode === document.body) {
      document.body.removeChild(this.loaderElement);
    }
  }
}
